// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; import './autoload/lazyload.js'; // eslint-disable-line
// Import local dependencies
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';
import 'select2/dist/js/select2.min';

function customAccordion(el) {
  let heading = $(el).find($('div[data-heading]')),
    content = $(el).find($('div[data-content]'));
  $(heading).on('click', function () {
    // $(el).toggleClass('tab-active');
    $(this).toggleClass('is-active').siblings(content).slideToggle();
  });
}

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  customAccordion($('.query__seasons-wrapper'));
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });
  $('.fancybox').fancybox();

  /**
   * Init parallax
   */
  jarallaxElement();
  jarallax(document.querySelectorAll('.jarallax'), {
    speed: 0.8,
    disableParallax: () => $(window).width() < 768,
  });

  let video = $('#video-file-item')[0];
  let playPauseButton = $('#playPauseButton');

  playPauseButton.on('click', function () {
    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
    playPauseButton.toggleClass('half-opacity');
  });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function () {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function (element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function (i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function () {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});

if ($('#wpadminbar').length) {
  $('.header-main.is-stuck').css('top', $('#wpadminbar').height() + 'px');
}

// function initJUIselect(elem) {
//   var $field = $(elem);
//   var $gfield = $field.closest('.gfield');
//   var args = {
//     change: function () {
//       $(this).trigger('change');
//     },
//   };
//   if ($gfield.length) {
//     args.appendTo = $gfield;
//   }
//   $field.selectmenu(args).on('change', function () {
//     $(this).selectmenu('refresh');
//   });
// }

// eslint-disable-next-line no-unused-vars
// $(document).on('gform_post_render', function (event, form_id, current_page) {
//   $('#gform_' + form_id)
//     .find('select')
//     .each(function () {
//       initJUIselect(this);
//     });
// });

$('.header .search__submit').on('click', function (e) {
  if (
    !$('.header .search__input').hasClass('active') &&
    !$(e.target).parents('.top-bar').length
  ) {
    e.preventDefault();
  }
  $('.header .search__input').addClass('active');
});

$('body').on('click', (e) => {
  if (
    !$(e.target).hasClass('search__input') &&
    !$(e.target).hasClass('search__submit')
  ) {
    $('.search__input').removeClass('active');
  }
});

$(document).on('gform_post_render', function (event, form_id) {
  $('#gform_' + form_id)
    .find('select')
    .prepend('<option selected=""></option>')
    .select2({
      minimumResultsForSearch: Infinity,
      placeholder: 'Select one...',
    });
});

// let gallery = $('.js-gallery-slider').slick({
//   fade: true,
//   adaptiveHeight: true,
//   nextArrow:
//     '<button type="button" class="slick-next"><i class="fal fa-arrow-right"></i></button>',
//   prevArrow:
//     '<button type="button" class="slick-prev"><i class="fal fa-arrow-left"></i></button>',
//   appendArrows: $(this).parents('.gallery-slider').find('.slider-arrows'),
// });

$('.js-gallery-slider').each(function () {
  let $thisParent = $(this).parents('.gallery-slider');
  let gallery = $(this).slick({
    fade: true,
    adaptiveHeight: true,
    nextArrow:
      '<button type="button" class="slick-next"><i class="fal fa-arrow-right"></i></button>',
    prevArrow:
      '<button type="button" class="slick-prev"><i class="fal fa-arrow-left"></i></button>',
    appendArrows: $thisParent.find('.slider-arrows'),
  });

  let $sliderContent = $thisParent.find('.js-gallery-content');
  if ($sliderContent.length) {
    gallery.slick('slickSetOption', 'asNavFor', $sliderContent);
  }
});

$('.js-gallery-content').slick({
  fade: true,
  adaptiveHeight: true,
  arrows: false,
  draggable: false,
});

let activeSubmenu = $('#sidebar-menu')
  .find('.current-page, .current_page_item, .current-menu-ancestor')
  .closest('ul');
if ($('#sidebar-menu').length) {
  $('#sidebar-menu').foundation('down', activeSubmenu);
}
